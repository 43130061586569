<template>
  <b-card no-body>
    <b-card-header class="pt-1 pb-1">
      <h5 class="mb-0 flex-grow-1 text-primary">
        Bộ lọc
      </h5>
      <div class="d-flex gap-1 align-items-center">
        <b-button
          v-b-tooltip.hover.bottom="'Làm mới bộ lọc'"
          variant="flat-primary"
          size="sm"
          class="p-0"
          @click="handleClearSearch"
        >
          <feather-icon
            :icon="searching ? 'SettingsIcon' : 'XSquareIcon'"
            size="24"
            class="cursor-pointer"
            :class="searching ? 'spinner' : ''"
          />
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          @click="handleSearch"
        >
          Tìm kiếm
        </b-button>
      </div>
    </b-card-header>
    <div class="card-body">
      <b-row>

        <b-col cols="12">
          <div class="form-group-hor mb-1">
            <label>Tỉnh</label>
            <div class="font-weight-bolder text-primary">
              Hồ Chí Minh
            </div>
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
          lg="3"
        >
          <!--              quận huyện-->
          <dynamic-form
            :id="'filter-form-quanhuyen'"
            :value="value.district_id_in"
            :type="'multi-select'"
            :label="'Quận/Huyện'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="districts"
            :select-default="false"
            @update-value="val => value.district_id_in = val"
          />

        </b-col>
        <b-col
          cols="12"
          sm="6"
          lg="3"
        >

          <!--              Phường/Xã-->
          <dynamic-form
            v-if="!value.district_id_in || value.district_id_in.length < 2"
            :id="'filter-form-phuongxa'"
            :value="value.ward_id_in"
            :type="'multi-select'"
            :label="'Phường/Xã'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="wardsByDistricts"
            :select-default="false"
            @update-value="val => value.ward_id_in = val"
          />

        </b-col>
        <b-col
          cols="12"
          sm="6"
          lg="3"
        >

          <!--              Đường-->
          <dynamic-form
            :id="'filter-form-duong'"
            :value="value.street_name_in"
            :type="'multi-select'"
            :label="'Đường'"
            value-field="name"
            text-field="name"
            :class="'form-group-hor'"
            :options="streetsByDistricts"
            :select-default="false"
            @update-value="val => value.street_name_in = val"
          />

        </b-col>
        <b-col
          cols="12"
          sm="6"
          lg="3"
        >

          <!--              Số nhà-->
          <dynamic-form
            :id="'filter-form-sonha'"
            :value="value.house_number"
            :type="'text'"
            :label="'Số nhà (chứa)'"
            :class="'form-group-hor'"
            @update-value="val => value.house_number = val"
          />

        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import useProductList from '@/views/product/list/useProductList'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    DynamicForm,
    BButton,
    BCardHeader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const searching = ref(null)

    const {
      wardsByDistricts, streetsByDistricts, districts, users, productListMenuType, productMenuType, productMenuTypeVI,
    } = useProductList()

    const handleSearch = () => {
      emit('update:loading', true)
      if (searching.value) {
        clearTimeout(searching.value)
        searching.value = null
      }
      // new search delay
      searching.value = setTimeout(() => {
        emit('search')
        // waiting for vue update -> parent -> child(here)
        setTimeout(() => {
          clearTimeout(searching.value)
          searching.value = null
        }, 50)
      }, 500)
    }

    const handleClearSearch = () => {
      searching.value = null
      emit('clear-filter', { province_id: 1, product_type: 'all' })
    }

    watch(props.value, () => {
      handleSearch()
    })
    watch([productListMenuType, productMenuType], () => {
      handleClearSearch()
    })

    return {
      searching,
      districts,
      productListMenuType,
      users,
      wardsByDistricts,
      productMenuType,
      productMenuTypeVI,
      streetsByDistricts,
      handleSearch,
      handleClearSearch,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';
</style>
